import { defineStore } from "pinia";

export const useAssistantStore = defineStore("assistantStore", {

	state: () => ({

		$assistantApi : useNuxtApp().$assistantApi,

    isOpen : false,

	}),

	actions: {

		getAssistant() {
			
			const response = this.$assistantApi.get('/assistants');

		}
	}

});